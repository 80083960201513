import React, { useState, useEffect } from 'react';
import { Button, Container, Box, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLoadScript } from '@react-google-maps/api';
import { RequestBookingService } from "../components/services/RequestBookingService"
import { CalendarToday } from '@mui/icons-material';
import SourceDestination from '../components/booking/common/SourceDestination'
import { dateFormat } from '../components/booking/components/common/dateUtils'
import CardImageRight from '../components/booking/common/CardImageRight'
import { useNavigate } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';

const libraries: ("places")[] = ["places"];

interface Form3Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBack: () => void;
  handleNext: () => void;
}

const NewBookingForm3: React.FC<Form3Props> = ({ formValues, handleInputChange, handleNext, handleBack, }) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: libraries
  });

  const navigate = useNavigate();


  const [duration, setDuration] = useState<string | null>(null);
  const [needCarrier, setNeedCarrier] = useState(false);
  const [carrierCharges, setCarrierCharges] = useState<number>(0);
  const [selectedExtraPickups, setSelectedExtraPickups] = useState<number>(0);
  const [extraWaiting, setExtraWaiting] = useState<number>(0);
  const [extraWaitingCharges, setExtraWaitingCharges] = useState<number>(0);
  const [extraKms, setExtraKms] = useState<number>(0);
  const [extraKmsCharges, setExtraKmsCharges] = useState<number>(0);
  const [extraPickup, setExtraPickup] = useState<number>(0);
  const [parking, setParking] = useState<number>(0);
  const [baseFare, setBaseFare] = useState<number>(3960);
  const [toll, setToll] = useState<number>(365)
  const [gst, setGst] = useState<number>(0);
  const [totalFare, setTotalFare] = useState<number>(4200);


  const bookingDateTime = `${formValues.bookingDate}T${formValues.bookingTime?.trimLeft()}`;

  useEffect(() => {
    calculateFare();
  }, []);

  const calculateFare = async () => {
    let localParking = 0;
    if (formValues.returnRide) {
      setBaseFare(6160);
      setToll(730)
    }

    if (formValues.source?.includes("Terminal 2") || formValues.source?.includes("T1, Navpada")) {
      localParking = 250;
    }

    let localextraKms = 0;
    if (formValues.distance !== null) {
      const distanceInt = formValues.distance;
      if (distanceInt && distanceInt > 160) {
        localextraKms = distanceInt - 160;
        setExtraKms(localextraKms);
      }
      else {
        setExtraKms(0);
      }
    }
    setExtraKmsCharges(localextraKms * 38);

    let extraPickupAmount = 0;
    const localSelectedExtraPickups = selectedExtraPickups;
    if (localSelectedExtraPickups > 0) {
      extraPickupAmount = 200 * localSelectedExtraPickups;
    }


    const amount = baseFare + toll + localParking + (localextraKms * 38) + extraPickupAmount + extraWaiting + carrierCharges;
    const gstInt = Math.ceil(amount * 0.05);
    setExtraPickup(extraPickupAmount);
    setParking(localParking);
    setGst(gstInt);
    const fare = amount + gstInt
    setTotalFare(fare);
  };




  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Box display="flex" alignItems="center">
            <CalendarToday sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
            <Typography sx={{ mr: 1, mb: 2 }}>Booking: {dateFormat(bookingDateTime)}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PeopleIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
            <Typography sx={{ mr: 1, mb: 2 }}>Number of Passengers: {formValues.numberOfPassengers}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LuggageIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
            <Typography sx={{ mr: 1, mb: 2 }}>Number of Big Bags: {formValues.numberOfBigBags}</Typography>
          </Box>
          <Divider sx={{ mb: 1 }} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <SourceDestination source={formValues.source} destination={formValues.destination} />
          <Divider sx={{ mt: 1 }} />
        </Grid>
        
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <CardImageRight header={'Luxury SUV+'} text={'Electric BYD E6'} imageSource={require('../images/BYD1.png')} ></CardImageRight>
        </Grid>

        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left" sx={{fontWeight:'bold'}}>Estimated Fare</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right"  sx={{fontWeight:'bold'}}>₹{RequestBookingService.getRequestBooking().booking.payment.totalFare}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left">Base Fare (160 km)</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right">₹{RequestBookingService.getRequestBooking().booking.payment.baseFare}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left">{"Extra Km Charges  " + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraKms}` + ' x ₹38 )'}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right">₹{RequestBookingService.getRequestBooking().booking.payment.extraKmCharges}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left">GST (5%)</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right">₹{RequestBookingService.getRequestBooking().booking.payment.gstFare}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left">Toll (Expressway)</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right">₹{RequestBookingService.getRequestBooking().booking.payment.toll}</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="left">Parking</Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Typography align="right">₹{RequestBookingService.getRequestBooking().booking.payment.parking}</Typography>
        </Grid>

        
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Button variant="contained" color="primary"
            onClick={handleNext}
            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              color: 'white',
              width: '90%'
            }}
            className="customButton"
          >
            Next
          </Button>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Button variant="contained" color="primary" onClick={handleBack}
            sx={{
              bgcolor: 'grey',
              '&:hover': {
                bgcolor: 'grey'
              },
              color: 'white',
              width: '90%'
            }}
            className="customButton"
          >Back</Button>
        </Grid>
      </Grid>
      <br></br>
    </div>
  );
};

export default NewBookingForm3;