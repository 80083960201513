import React, { useState } from 'react';
import { Button, Box, Typography, TextField, MenuItem, Slide, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import SourceDestination from '../components/booking/common/SourceDestination'
import { AppContext } from '../components/services/AppContext'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { RequestBookingService } from "../components/services/RequestBookingService"
import { TransitionProps } from '@mui/material/transitions';
import CardImageRight from '../components/booking/common/CardImageRight'
import '../styles/CommonCSS.css';

const libraries: ("places")[] = ["places"];



interface Form2Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement }, // Ensure children is a ReactElement
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewBookingForm2: React.FC<Form2Props> = ({ formValues, handleInputChange, handleNext, handleBack }) => {


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Use environment variable for API key
    libraries: libraries
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<string>(AppContext.travelDate);
  const [selectedTime, setSelectedTime] = useState<string>(AppContext.travelTime);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fareDetails = [
    { name: 'Estimated Fare', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.totalFare}` },
    { name: 'Base Fare (160 km)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.baseFare}` },
    { name: "Extra Km Charges " + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraKms}` + ' x ₹38)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.extraKmCharges}` },
    // { name: 'Extra Pickup/Drop ' + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraPickorDrop}` + ' x ₹200)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.extraPickorDropCharges}` },
    // { name: 'Carrier Charges', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.carrierCharges}` },
    { name: 'GST (5% )', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.gstFare}` },
    { name: 'Toll (Express way)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.toll}` },
    { name: 'Parking', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.parking}` },
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    handleInputChange(event)
    AppContext.travelDate = event.target.value
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value)
    handleInputChange(event)
    AppContext.travelTime = event.target.value
  };

  const generateTimeOptions = () => {
    const times = [];
    const formatTime = (hour: number, minute: number) => {
      return ` ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        times.push(formatTime(hour, minute));
      }
    }
    return times;
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleNextClicked = () => {
    if (!AppContext.travelDate) {
      setSnackbarMessage("Please select booking date.");
      setSnackbarOpen(true);
      return
    }
    if (!AppContext.travelTime) {
      setSnackbarMessage("Please select booking time");
      setSnackbarOpen(true);
      return
    }
    if (!formValues.numberOfBigBags) {
      setSnackbarMessage("Please select number of bags bigs.");
      setSnackbarOpen(true);
      return
    }
    if (!formValues.numberOfPassengers) {
      setSnackbarMessage("Please select number of passengers");
      setSnackbarOpen(true);
      return
    }
    handleNext()
  }


  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <SourceDestination source={formValues.source} destination={formValues.destination} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Total Fare (BYD E6) : ₹{RequestBookingService.getRequestBooking().booking.payment.totalFare}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Including Expressway Toll, Gst, Airport parking & Extra km
              </Typography>
            </CardContent>
            <Button size="small" onClick={handleClickOpen} sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              color: 'white',
              m: 3
            }} >Fare Details</Button>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            type="date"
            name="bookingDate"
            label="Booking Date"
            fullWidth
            value={AppContext.travelDate}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split('T')[0], // today onwards
            }}
            variant="outlined"
            required
            sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            name="bookingTime"
            label="Booking Time"
            fullWidth
            value={AppContext.travelTime}
            onChange={handleTimeChange}
            variant="outlined"
            required
            sx={{ bgcolor: 'white', borderRadius: 1, mt: 2, textAlign: 'left' }}
          >
            {generateTimeOptions().map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            label="Number Of Passengers"
            fullWidth
            name="numberOfPassengers"
            value={formValues.numberOfPassengers.toString()}
            onChange={handleInputChange}
            required
            //type="number"
            sx={{ bgcolor: 'white', borderRadius: 1, mt: 2, textAlign: 'left' }}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            label="Number Of Big Bags"
            fullWidth
            name="numberOfBigBags"
            value={formValues.numberOfBigBags.toString()}
            onChange={handleInputChange}
            required
            //type="number"
            sx={{ bgcolor: 'white', borderRadius: 1, mt: 2, textAlign: 'left' }}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
              '& .MuiSnackbarContent-root': {
                maxWidth: '400px',
                width: '100%',
              },
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '90%',
                width: '90%',
              },
            }}

            aria-live="assertive"
          >
            <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
          </Snackbar>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Button variant="contained" color="primary" onClick={handleNextClicked}
            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              color: 'white',
              width: '90%'
            }}
            className="customButton"
          >Next
          </Button>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Button variant="contained" color="primary" onClick={handleBack}
            sx={{
              bgcolor: 'grey',
              '&:hover': {
                bgcolor: 'grey'
              },
              color: 'white',
              width: '90%'
            }}
            className="customButton"
          >Back
          </Button>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { width: '80%' } // Set to your desired width
          }}
        >
          <DialogTitle>
            <Typography textAlign="left" variant="h5">Estimated Fare</Typography>
          </DialogTitle>
          <DialogContent>
            <div>
              <CardImageRight header={'Luxury SUV+'} text={'Electric BYD E6'} imageSource={require('../images/BYD1.png')} ></CardImageRight>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {fareDetails.map((detail, index) => (
                  <>
                    <Grid size={{ xs: 9, sm: 9, md: 9 }}>
                      <span key={index} style={{
                        fontSize: detail.name === 'Estimated Fare' ? '16x' : '14px',
                        fontWeight: detail.name === 'Estimated Fare' ? 'bold' : 'normal',
                      }}>{detail.name}</span>
                    </Grid>
                    <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                      <span key={index} style={{
                        fontSize: detail.name === 'Estimated Fare' ? '16px' : '14px',
                        fontWeight: detail.name === 'Estimated Fare' ? 'bold' : 'normal',
                      }}>{detail.fare}</span>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <div>
              <Button onClick={handleClose} color="primary" sx={{
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                },
                color: 'white',
                m: 1
              }}>
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Grid>
      <br></br>
      <br></br>
    </div >
  );
};

export default NewBookingForm2;
